.CampaignPost_wrap .Page_compact .Page_children {
  min-height: 0;
}

.CampaignPost_heroImage {
  height: calc(100vh - 100px);
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}

@media all and (max-width: 1024px) {
  .CampaignPost_heroImage {
    height: calc(100vh - 54px);
  }
}

.CampaignPost_titleWrap {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.CampaignPost_title {
  font-size: 72px;
  line-height: 1;
}

@media all and (max-width: 1024px) {
  .CampaignPost_title {
    font-size: 24px;
    line-height: 1;
  }
}

.CampaignPost_snippet {
  font-size: 24px;
  line-height: 1;
}

@media all and (max-width: 1024px) {
  .CampaignPost_snippet {
    font-size: 18px;
    line-height: 1;
  }
}

.CampaignPost_scrollButton {
  border: none;
  outline: none;
  background: white;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  position: absolute;
  bottom: 90px;
  left: 50%;
  transform: translate(-50%);
  display: block;
}

.CampaignPost_scrollButton:before {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.EventPost_content {
  margin-top: 120px;
}
